import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependencies';
window.libs = libs;

$(document).foundation();

libs.AOS.init();

// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('img.inject-me');

// Options
var injectorOptions = {
  evalScripts: 'once',
  pngFallback: 'assets/png'
};

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);

// slick carousel
$(".content-carousel").slick({
  // normal options...
  speed: 5000,
	autoplay: true,
	autoplaySpeed: 0,
	cssEase: 'linear',
  slidesToShow: 5,
	slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
	centerMode: true,
  focusOnSelect: true,
  // the magic
  responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        infinite: true
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    }, {
      breakpoint: 300,
      settings: "unslick" // destroys slick
    }]
});

// tablesaw table plugin
$(function () {
  $(document)
    // .foundation() //oh my, wer schreibt sowas so weitreichendes einfach rein
    .trigger('enhance.tablesaw');
});

var TablesawConfig = {
  swipeHorizontalThreshold: 15
};

// app dashboard toggle
$('[data-app-dashboard-toggle-shrink]').on('click', function(e) {
  e.preventDefault();
  $(this).parents('.app-dashboard').toggleClass('shrink-medium').toggleClass('shrink-large');
});










////////// CUSTOM CODE //////////////

function monthToText(month) {

    switch (parseInt(month)) {
        case 0:
            return 'Dezember';
            break;
        case 1:
            return 'Weihnachten';
            break;
        case 2:
            return 'Januar';
            break;
        case 3:
            return 'Februar';
            break;
        case 4:
            return 'März';
            break;
        case 5:
            return 'Ostern';
            break;
        case 6:
            return 'April';
            break;
        case 7:
            return 'Mai';
            break;
        case 8:
            return 'Pfingsten';
            break;
        case 9:
            return 'Juni';
            break;
        case 10:
            return 'Juli';
            break;
        case 11:
            return 'August';
            break;
        case 12:
            return 'September';
            break;
        case 13:
            return 'Oktober';
            break;
        case 14:
            return 'November';
            break;
        default:
            return 'no such month';
    }

};

function rotateSourceOrder(month) {
    var month = parseInt(month);

    for (var i = 0; i < 15; i++) {
        if (i - month >= 0) {
            $(".monatsitem.month" + i).css("order", i - month);
        } else {
            $(".monatsitem.month" + i).css("order", i - month + 15);
        }
    }
};


function getAngle(month) {
    var degrees = parseInt(month) * (-24); // 360°/15 = 24 

    if (degrees < -180) { // ->drehbarkeit in beide richtungen
        degrees = 360 + degrees;
    }
    return degrees;
};

var granddelay = 5;
var smalldelay = 0.3;

function initkreis_base(month) {
    //console.log(month);

    var degrees = getAngle(month);

    $("#aktuellermonat").html(monthToText(month));
    $("#sinnspruch").html($(".monatsitem.month" + month + " h6.subtitle").html());

    $("#kreissvg").css("transition", "transform 0."+smalldelay+"s ease");
    $("#kreissvg").css("transform", "rotate(" + degrees + "deg)");

    //remove stuff
    $(".monatsitem>div>h2").removeClass("underline");
    $(".monatsitem div.show-for-small").addClass("show-for-medium");
    $(".monatsitem div.show-for-small").removeClass("show-for-small");
    $('ul.accordion').foundation('up', $('ul.accordion .accordion-content'));

    //add stuff
    $(".monatsitem.month" + month + ">div>h2").addClass("underline");
    rotateSourceOrder(month);
    $(".monatsitem.month" + month + " div.show-for-medium").addClass("show-for-small");
    $(".monatsitem.month" + month + " div.show-for-medium").removeClass("show-for-medium");
    $(".month" + month + " ul.accordion").foundation('down', $(".month" + month + " .accordion-item:first-child>.accordion-content"));

    //scroll
    function scrollmetothecontent() {
        $('html, body').animate({
            scrollTop: ($("#liste").offset().top)
        }, 'normal');
    } setTimeout(scrollmetothecontent, granddelay*100);

}
function initkreis(month) {
    //console.log(month);
    // console.log(this);
    // var mymonths = ['dezember','weihnachten','januar','februar','marz','ostern','april','mai','pfingsten','juni','juli','august','september','oktober','november'];
    // $('#'+mymonths[month]).attr('filter', 'url(#f2)');
    var degrees = getAngle(month);

    $("#aktuellermonat").html(monthToText(month));
    $("#sinnspruch").html($(".monatsitem.month" + month + " h6.subtitle").html());

    $("#kreissvg").css("transition", "transform 0."+granddelay+"s ease");


    // function lift() {
    //     $("#kreissvg").css("transform", "scale(1.1)");
    //     // $("#kreissvg").css("box-shadow", "10px 10px 0px rgba(0,0,0,0.4)");
    // } setTimeout(lift, smalldelay*0);
    function rotator() {
        $("#kreissvg").css("transform", "scale(1.1) rotate(" + degrees + "deg)");
        // $("#kreissvg").css("background", "radial-gradient(at center center, rgba(0, 0, 0, 0.3) 3%,  rgba(255, 255, 255, 0) 67%)");
    } setTimeout(rotator, smalldelay*0);
    function lower() {
        $("#kreissvg").css("transform", "scale(0.9) rotate(" + degrees + "deg)");
        // $("#kreissvg").css("background", "radial-gradient(at center center, rgba(0, 0, 0, 0.1) 3%,  rgba(255, 255, 255, 0) 67%)");
        // $("#kreissvg").css("box-shadow", "0px 0px 0px rgba(0,0,0,0.1)");
    } setTimeout(lower, smalldelay*800);





    //remove stuff
    $(".monatsitem>div>h2").removeClass("underline");
    $(".monatsitem div.show-for-small").addClass("show-for-medium");
    $(".monatsitem div.show-for-small").removeClass("show-for-small");
    $('ul.accordion').foundation('up', $('ul.accordion .accordion-content'));

    //add stuff
    $(".monatsitem.month" + month + ">div>h2").addClass("underline");
    rotateSourceOrder(month);
    $(".monatsitem.month" + month + " div.show-for-medium").addClass("show-for-small");
    $(".monatsitem.month" + month + " div.show-for-medium").removeClass("show-for-medium");
    $(".month" + month + " ul.accordion").foundation('down', $(".month" + month + " .accordion-item:first-child>.accordion-content"));

    //scroll
    function scrollmetothecontent() {
        $('html, body').animate({
            scrollTop: ($("#liste").offset().top)
        }, 'normal');
    } 
    setTimeout(scrollmetothecontent, granddelay*150);

}

function realinitkreis(month) {
    var degrees = getAngle(month);

    $("#aktuellermonat").html(monthToText(month));
    $("#sinnspruch").html($(".monatsitem.month" + month + " h6.subtitle").html());

    $("#kreissvg").css("transform", "rotate(" + degrees + "deg)");
    $(".monatsitem.month" + month + ">div>h2").addClass("underline");
    rotateSourceOrder(month);
    $(".monatsitem.month" + month + " div.show-for-medium").addClass("show-for-small");
    $(".monatsitem.month" + month + " div.show-for-medium").removeClass("show-for-medium");
    $(".month" + month + " ul.accordion").foundation('down', $(".month" + month + " .accordion-item:first-child>.accordion-content"));

}

$(document).ready(function () {
    realinitkreis(0);
    $('#dezember').click(function() { initkreis(0) });
    $('#weihnachten').click(function() { initkreis(1) });
    $('#januar').click(function() { initkreis(2) });
    $('#februar').click(function() { initkreis(3) });
    $('#marz').click(function() { initkreis(4) });
    $('#ostern').click(function() { initkreis(5) });
    $('#april').click(function() { initkreis(6) });
    $('#mai').click(function() { initkreis(7) });
    $('#pfingsten').click(function() { initkreis(8) });
    $('#juni').click(function() { initkreis(9) });
    $('#juli').click(function() { initkreis(10) });
    $('#august').click(function() { initkreis(11) });
    $('#september').click(function() { initkreis(12) });
    $('#oktober').click(function() { initkreis(13) });
    $('#november').click(function() { initkreis(14) });
    // initkreis(0);
    // var mymonths = ['dezember','weihnachten','januar','februar','marz','ostern','april','mai','pfingsten','juni','juli','august','september','oktober','november'];
    // for (var i in mymonths) {
    //     $('#'+mymonths[i]).click(function() {  
    //         console.log(i);
    //         initkreis(i);
    //     });
    // }
});